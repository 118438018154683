<template>
  <div class="areaAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="100px" size="small">
          <el-form-item label="区域名称:" prop="areaName">
            <el-input v-model="inform.areaName" placeholder="请输入区域名称"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api'
import qs from 'qs'
var vm;
export default {
  data() {
    return {
      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        areaName:'',//区域名称
        parentId:'',
      },

      rules :{
        areaName: [
          { required: true, message: '请输入区域名称', trigger: "blur" }
        ],
      },
    }
  },
  created(){
    vm = this
  },
  mounted(){
    
  },

  //方法
  methods: {
    //初始化
    init (type,item){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '640px'
      vm.isDio = true

      vm.$nextTick(() => {
        if(type == 'add'){
          vm.dioTit = '新增区域'
          if(item){
            vm.inform.parentId = item.id
          }
        }else if(type == 'edit'){
          vm.dioTit = '编辑区域'
          vm.inform = item
        }
      })
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if(vm.dioType=='add'){
            vm.createArea()
          }else if(vm.dioType=='edit'){
            vm.modifyArea()
          }
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })
      vm.inform ={
        id:'',
        areaName:'',//区域名称
        parentId:'',
      }
      vm.isDio = false
    },
    //新增
    async createArea(){
      let dataObj = {
        'areaName': vm.inform.areaName,//区域名称
      }
      if(vm.inform.parentId){
        dataObj.parentId = vm.inform.parentId
      }
      const res = await apiUrl.createArea(dataObj)
      if(res?.code==200){
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //修改
    async modifyArea(){
      let dataObj = {
        'id':vm.inform.id,
        'areaName': vm.inform.areaName,//区域名称
      }
      const res = await apiUrl.modifyArea(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    }
  }
}
</script>
<style lang='scss'>
.areaAddEdit{
  
}
</style>