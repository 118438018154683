<template>
  <div class='safety-area mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="searchCont mb15">
      <div class="title_name"><div>检索条件</div></div>
      <el-form ref="searchForm" :model="keyWord" label-width="100px" inline @submit.native.prevent size="small">
        <el-form-item label="区域名称:" prop="name">
          <el-input type="text" class="w180" v-model="keyWord.name" placeholder="请输入区域名称"/>
        </el-form-item>
      </el-form>
    </div>
    <div class="pageCont">
      <div class="title_name">
        <div>列表详情</div>
        <div>
          <el-button type="text" @click="openDio('add')" v-if="isAuth('safe:safety:area:add')"><i class="el-icon-circle-plus-outline"></i>添加</el-button>
        </div>
      </div>
      <div class="tableDivPage mt10" :style="{'height':tableH+'px'}">
        <el-tree
          class="zoneTree"
          :data="treeData"
          :props="treeProps"
          default-expand-all
          :node-key="treeProps.key"
          :filter-node-method="filterNode"
          ref="zoneTree"
          :expand-on-click-node="false"
          :highlight-current = "true"
          @node-click="treeClick"
          empty-text="暂无数据">
          <span class="zoneNode" slot-scope="{ node, data }">
            <span class="treeLabel" :title="node.label">{{ node.label }}</span>
            <span class="ml20">
              <el-button title="新增子区域" v-if="isAuth('safe:safety:area:add')" icon="el-icon-circle-plus-outline" type="text" @click="()=>openDio('add', data)"></el-button>
              <el-button title="编辑" v-if="isAuth('safe:safety:area:edit')" icon="el-icon-edit" type="text" @click="()=>openDio('edit',data)"></el-button>
              <el-button title="删除" v-if="isAuth('safe:safety:area:del')" icon="el-icon-remove-outline" type="text" @click="()=>openDio('del',data)"></el-button>
            </span>
          </span>
        </el-tree>
      </div>
    </div>

    <!--创建、修改-->
    <area-add-edit ref="editDio" @addEditSucc="getList()"></area-add-edit>

  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb.vue'
import areaAddEdit from './area-add-edit.vue'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { formatTime} from '@/utils/utils'
var vm;
export default {
  name:'safety-area',
  data() {
    return {
      //筛选数据
      keyWord :{
        name:'',
      },
      treeData:[],//树形数据
      treeProps:{
        children: 'childrenList',
        label: 'areaName',
        key:'id'
      },
      
      tableH :'',//表格高度
    }
  },
  components:{
    areaAddEdit,
    breadCrumb,
  },
  watch:{
    'keyWord.name'(val){
      this.$refs.zoneTree.filter(val)
    },
  },
  created(){
    vm = this
    vm.initHeight()
    let userInfo = this.vtp.get('userInfo')
    if(userInfo){
      this.isAdmin = userInfo.isAdmin
    }
  },
  mounted(){
    vm.$nextTick(() => {
      vm.getList()
    })
    
    window.onresize=() => {
      vm.initHeight()
    }
  },

  //方法
  methods:{
    //过滤筛选
    filterNode (value, data) {
      if (!value) return true
      return data.areaName.includes(value)
    },
    //删除区域
    async deleteArea(id){
      let dataObj = {
        'areaId':id
      }
      const res = await apiUrl.deleteArea(qs.stringify(dataObj))
      if(res?.code==200){
        this.$message.success('删除成功！')
        this.getList()
      }else{
        this.$message.error(res.message)
      }
    },
    //获取树形区域数据
    async getList(){
      const res = await apiUrl.getAreaList()
      if(res?.code==200){
        this.treeData = res.data
        this.$store.state.changeAreaNum += 1
      }else{
        this.$message.error(res.message)
      }
    },
    //点击节点
    treeClick (data,node){
      // console.log(data);
    },
    //打开弹窗
    openDio (type,item){
      if(type=='del'){
        this.$confirm('确定删除该选项','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        .then(() => {
          this.deleteArea(item.id)
        })
        .catch(() => {})
      }else if(type=='add'||type=='edit'){
        this.$refs.editDio.init(type,item?JSON.parse(JSON.stringify(item)):null)
      }
    },
    //设置高度
    initHeight (){
      var minWin = window.innerHeight;
      vm.tableH = minWin-270
    }
  }
}
</script>
<style lang='scss'>
.safety-area{
  .tableDivPage{
    overflow: auto;
  }
}
</style>
